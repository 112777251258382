<template>
    <div class="bg-white" id="prs_container">
        <div id="header">
            <slot name="header">

            </slot>
        </div>
        <div id="body">
            <slot name="body">

            </slot>
        </div>

        <div id="footer">
            <slot name="footer">

            </slot>
        </div>
   
    </div>
</template>
<script>
export default {
    name: 'PRSContainer',
}
</script>
<style scoped>
#prs_container{
    height: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    overflow: auto;

}
#header{
    /* margin-bottom: auto; */
    margin-bottom: 15px;
}

#body {
    /* padding: 15px; */
    min-height: 100%;
    max-height: 100%;

    /* overflow: auto; */
}
#footer {
    margin-top: 10px;
}
</style>