
<template>
    <prsContainer>
        <template #header >
            <div class="d-flex mb-3">
                <input type="text" class="form-control form-control-sm" placeholder="Search..." style="max-width:50%" v-model="SearchString">
                <button class="btn btn-info btn-sm ml-3" style="align-self: center;"  @click="Search(SearchString)">Search</button>
                <button class="btn btn-secondary btn-sm ml-3" style="align-self: center;"  @click="refresh">Refresh</button>
                <button class="btn btn-primary btn-sm ml-auto" @click="show">Create</button>
            </div>
        </template>

       <template #body>
          <table class="ks-table table-sm table-hover" >
                <thead>
                    <tr>
                        <th>
                           #
                        </th>
                        <th>
                            PRS No.
                        </th>
                        <th>
                            PRS TYPE
                        </th>
                        <th>
                            REQUESTED BY
                        </th>
                        <th>
                            PRS Date
                        </th>
                        <th>

                        </th>
                    </tr>
                </thead>
                <tbody v-if="prs.length > 0" >
                    <tr v-for="(data,index) in prs" :key="index" @click="showEdit(index)"> 
                        <td> {{ index + 1 }} </td>
                        <td> {{ data.prs_header.PRS_NUMBER }} </td>
                        <td> {{ data.prs_header.PRS_TYPE }} </td>
                        <td> {{ data.prs_header.REQUESTED_BY }} </td>
                        <td> {{ setDate(data.prs_header.PRS_DATE) }} </td>
                        <td>
                            <button class="btn btn-sm btn-primary" @click="generatePDF(index)">
                                <span class="icon-addon">
                                <span class="la la-eye"></span>
                                </span>
                            </button> 
                            <!-- <button class="btn btn-sm btn-secondary ml-3  ">
                                DL
                            </button> -->
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="5">
                            No data found
                        </td>
                    </tr>
                </tbody>
          </table>
          
       </template>
    </prsContainer>


</template>
<script>
// import prsModal from './prs_modal.vue';
import  jsPDF  from "jspdf";
import  autoTable from 'jspdf-autotable'
import html2pdf from "html2pdf.js";
import prsContainer from './prs_container.vue'

export default {
    props: ['prs'],
    name:'PRSmain',
    inject: ['show','showEdit','Search','refresh'],
    components: {
            // prsModal,
            prsContainer
    },
    data(){
        return {
            showCreate : false,
            prs_header : [],
            SearchString: ''
        }
    },
    mounted(){
    //   this.retrievePRS()

    },
    methods : {

        setDate(PRS_DATE){
            // let date = this.API.converDateToString(PRS_DATE)
            let date = this.$moment(PRS_DATE).format('LL'); 
            return date
        },
        showModal(){
            this.show = !this.show;
        },


        exportToPDF() {
            var element = document.getElementById("element");
            var opt = {
                margin:       .5,
                image:        { type: 'jpeg', quality: 0.98 },
                // html2canvas: {dpi: 300, letterRendering: true, width: 100, height: 100, windowWidth: 100, windowHeight: 100},
                html2canvas : {scale : 1.2},
                jsPDF:        { unit: 'cm', format: 'legal', orientation: 'portrait' }
            }
            // html2pdf().set(opt).from(element).save();
            html2pdf().set(opt).from(element).save('my_file.pdf','f');


         },

         

         generatePDF(index){
            const doc = new jsPDF(
                {
                    orientation: 'p',
                    unit: 'mm',
                    format: 'a4',
                    putOnlyUsedFonts:true
                }
            )

            // const formatter = function(data) {
            //     parseFloat(data).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); 
            // }
    
            const selected_prs = this.prs[index];
            const header =  [
                
                [{colSpan: 2, content: 'Quantity'},{rowSpan: 2, content: 'Description'},{colSpan: 4, content: 'Unit Cost'}],
                [ 'On Hand','Requested','Supplier 1','Supplier 2','Supplier 3','Total Cost']
            
            ]

            const body = []
            var data = []

            let all_total = 0
            let currency = ''

            selected_prs.prs_detail.forEach( function(rows){
                let sup1 = rows.SUPPLIER_1
                let sup2 = rows.SUPPLIER_2 
                let sup3 = rows.SUPPLIER_3 
                let total = rows.TOTAL_COST

                if(sup1  != 0 ){
                    sup1 = parseFloat(sup1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); 
                    sup1 = rows.CURRENCY + ' ' + sup1
                }
                if(sup2  != 0 ){
                    sup2 = parseFloat(sup2).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); 
                    sup2 = rows.CURRENCY + ' ' + sup2
                }
                if(sup3 != 0 ){
                    sup3 = parseFloat(sup3).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); 
                    sup3 = rows.CURRENCY + ' ' + sup3
                }

                currency = rows.CURRENCY

                all_total = all_total + (total * 1)
            

                total = parseFloat(total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); 
                total = rows.CURRENCY + ' ' + total


                // data = [rows.ON_HAND,rows.REQUESTED,
                //             rows.DESCRIPTION,sup1,sup2,sup3,
                //             total]
                data = [ {content: rows.ON_HAND},{content:rows.REQUESTED}, {content : rows.DESCRIPTION }, {content: sup1 }, {content: sup2 }, {content: sup3 },{content: total} ]
                            // {colSpan:7, content:' '}
                body.push(data)
            })


            //for totals
            all_total = (all_total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); 
            let emp_array = [{colSpan:7, content:' '}]
            let total_array = [{colSpan:7,content: currency + ' ' + all_total }]

            body.push(emp_array)
            body.push(total_array)



            var img = new Image()
            //set src
            img.src = require('@/assets/logo.png')
            //convert img to base64
            this.encodeImage(img)

            //addPage set to desire size (a0 - a10,b0 - b10, c0 - c10,dl,letter,government-letter,government-letter,junior-legal,ledger,tabloid,credit-card, or numeric w h)and landscape or portrait 'l' or 'p'
            doc.addPage()
            //delete the first page (meron kasing laman kaagad kaya denelete ko hehe)
            doc.deletePage(1)
       

            doc.addImage(img, 'png', 15, 3, 45, 15)


            doc.setFontSize(14)
            doc.setFont(undefined,'bold')
            doc.text('PURCHASE REQUISITION SLIP',110,20,'center')

            doc.setFontSize(12)
            doc.setFont(undefined,'normal')
            let date = new Date()

         
            let year = date.getFullYear()
      

            let prs_num = year + '-' + selected_prs.prs_header.PRS_NUMBER

     

            doc.setFontSize(10)
            let prsDateToday = this.$moment(date).format('LL');
            doc.text('Date : ' + prsDateToday,154,30) 
            
            doc.setFont(undefined,'italic')
   
            doc.setTextColor('red')
            doc.text('PRS NO. ' + prs_num , 154,20)
            doc.setTextColor('black')
            doc.setFont(undefined,'normal')
            ////PRS TYPE
            doc.text('Imported Purchase',18,40)
            doc.text('Local Purchase',83,40) 
            doc.text('_____________________',154,40) 
            doc.text('Office Service',18,50,'left') 
            doc.text('Warehouse Service Purchase',83,50) 
            doc.text('_____________________',154,50) 

        

            autoTable(doc,
             { 
                // html: '#tables',
                head: header,
                body: body,    
                styles: { halign: 'center',
                                cellPadding: 1,
                                lineWidth: 0.2,
                                lineColor: [121, 122, 122], },
                // theme:'plain',
                didParseHeader: function(data) {
                        data.table.head.forEach(row => {
                            row.styles.fillColor = [0, 0, 200];
                        });
                    },
                didParseCell: function(data) {


                    
                    if(data.section == 'body'){
                        if(data.column.index == 3){
                            // if(selected_prs.prs_detail.S1 == 1){
                            //     data.cell.styles.fillColor = [255, 255, 0];
                            // }

                            // selected_prs.prs_detail.forEach(function(row){
                            //         if(row.S1 == 1){
                            //             data.cell.styles.fillColor = [255, 255, 0];
                            //         }
                            // })
                            if(selected_prs.prs_detail[data.row.index].S1 == 1){
                                data.cell.styles.fillColor = [255, 255, 0];
                            }
                        }

                        if(data.column.index == 4){
                            // if(selected_prs.prs_detail.S1 == 1){
                            //     data.cell.styles.fillColor = [255, 255, 0];
                            // }
                            if(selected_prs.prs_detail[data.row.index].S2 == 1){
                                data.cell.styles.fillColor = [255, 255, 0];
                            }
                        }

                        if(data.column.index == 5){
                            // if(selected_prs.prs_detail.S1 == 1){
                            //     data.cell.styles.fillColor = [255, 255, 0];
                            // }
                            if(selected_prs.prs_detail[data.row.index].S3 == 1){
                                data.cell.styles.fillColor = [255, 255, 0];
                            }
                            
                        }

                        console.log(data)

                    }


                        data.cell.styles.fontSize = 8;

                        var rows = data.table.body;
                        if (data.row.index === rows.length - 1) {
                            data.cell.styles.halign = "right";
                        }

                        // if(data.section == 'body'){
                        //     data.cell.styles.fillColor = [255, 255, 0];
                        // }

                },
                tableWidth: '100.058mm',
                startY: 60,
                
            })

            //Supplier details
            // let sup1 = selected_prs.prs_supplier.SUPPLIER_1, sup2 = selected_prs.prs_supplier.SUPPLIER_2, sup3 = selected_prs.prs_supplier.SUPPLIER_3
            if(selected_prs.prs_supplier.length >  0){  
                let x = 15
                let y = 225
                let px = 140
                selected_prs.prs_supplier.forEach(  (data) =>
                    {
                        doc.text('Supplier (1) Name :  ' + data.NAME ,x,y)
                        doc.text('Phone # : ' + data.CONTACT_NUMBER,px,y)

                       y =  y + 10
                    }
                )

            } 
  
                doc.text('Supplier (1) Name :',15,225)

    
                doc.text('Supplier (2) Name :',15,235) 

                doc.text('Supplier (3) Name :',15,245) 
            
            let remarks = selected_prs.prs_header.REMARKS.replace(/(\r\n|\n|\r)/gm, "");
            var textLines = doc.splitTextToSize(remarks, 150);

            doc.text('Remarks : ',15,255) 

            // doc.text(remarks, 33,255);

            for (var i = 0; i < textLines.length; i++) {
                    doc.text(textLines[i], 33, 255 + (i * 5));
                }

            // doc.text('Date Needed :  ' + this.API.converDateToString(selected_prs.prs_header.PRS_DATE),15,265) 
            let formatPRSdate = this.$moment(selected_prs.prs_header.PRS_DATE).format('LL');
            doc.text('Date Needed :  ' + formatPRSdate ,15,265) 

            // doc.text('___________________',160,50) 


            doc.text('Phone # :',140,225)
            doc.text('Phone # :',140,235) 
            doc.text('Phone # :',140,245) 


            ///
            doc.setFontSize(8)

            doc.text('Requested By :',15,275) 
            doc.text('Noted/Canvas By :',72,275) 
            doc.text('Approved By :',142,275) 

            doc.setDrawColor(0, 0, 0);
            doc.setLineWidth(1);

            // draw a line from (20, 20) to (80, 20)
            ///horizontal line
            doc.line(15, 270, 195, 270);

            doc.line(15, 295, 195, 295);

            doc.setLineWidth(0.5);
        
            //vertical line
            doc.line(70, 270, 70, 295);
            doc.line(140, 270, 140, 295);

            doc.setLineWidth(0.3);

            //dateneeded 
            doc.line(39, 266, 100, 266);
            //remarks
            doc.line(33, 256, 195, 256);
            //Supplier3
            doc.line(47, 246, 130, 246);
            //Supplier2
            doc.line(47, 236, 130, 236);
            //Supplier3
            doc.line(47, 226, 130, 226);
            //Supplier Phone #3
            doc.line(156, 246, 195, 246);
            //Supplier Phone #2
            doc.line(156, 236, 195, 236);
            //Supplier Phone #
            doc.line(156, 226, 195, 226);


            doc.setDrawColor(0, 0, 0);
            doc.setLineWidth(0.5);
            // doc.setFillOpacity(0.5);


            
            // 'S' (stroke), 'F' (fill), or 'DF' (stroke and fill).
            doc.rect(14, 37.5, 2.5, 2.5, 'S');
            doc.rect(79, 37.5, 2.5, 2.5, 'S');
            doc.rect(150, 37.5, 2.5, 2.5, 'S');

            doc.rect(14, 47.5, 2.5, 2.5, 'S');
            doc.rect(79, 47.5, 2.5, 2.5, 'S');
            doc.rect(150, 47.5, 2.5, 2.5, 'S');



            doc.setFillColor(0, 255, 0);

            switch(selected_prs.prs_header.PRS_TYPE){

                case 'Imported Purchase' : 
                    doc.rect(14, 37.5, 2.5, 2.5, 'F');
                break
                
                case 'Local Purchase' : 
                    doc.rect(79, 37.5, 2.5, 2.5, 'F');
                break

                case 'Office Service' : 
                    doc.rect(14, 47.5, 2.5, 2.5, 'F');
                break

                case 'Warehouse service purchase' : 
                    doc.rect(79, 47.5, 2.5, 2.5, 'F');
                break

                default : 
                    doc.rect(150, 37.5, 2.5, 2.5, 'F');
                    doc.text(selected_prs.prs_header.PRS_TYPE, 154,40) 

                break
            }


            doc.setProperties({
                title: "Print " + year + '-' + selected_prs.prs_header.PRS_NUMBER
            });
            
            doc.output('dataurlnewwindow')
            // doc.output('data')

         },

         encodeImage(img){
        
            const getBase64StringFromDataURL = (dataURL) =>
            dataURL.replace('data:', '').replace(/^.+,/, '');

            fetch(img.src)
                .then((res) => res.blob())
                .then((blob) => {
                    // Read the Blob as DataURL using the FileReader API
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        // console.log(reader.result);
                        // Logs data:image/jpeg;base64,wL2dvYWwgbW9yZ...
                        this.image = reader.result;

                        // Convert to Base64 string
                        const base64 = getBase64StringFromDataURL(reader.result);
                        console.log(base64);
                        // Logs wL2dvYWwgbW9yZ...
                    };
                    reader.readAsDataURL(blob);
                });
        },
        Convert_HTML_To_PDF() {
            var doc = new jsPDF();
            
            // Source HTMLElement or a string containing HTML.
            var elementHTML = document.querySelector("#element");

            doc.html(elementHTML, {
                callback: function(doc) {
                    // Save the PDF
                    doc.save('document-html.pdf');
                },
                margin: [5, 5, 5, 5],
                autoPaging: 'text',
                x: 0,
                y: 0,
                width: 190, //target width in the PDF document
                windowWidth: 675 //window width in CSS pixels
            });
        },

    }
}
</script>
<style scoped>
th,td {
    text-align: center;
}
tr {
    cursor: pointer;
}

#element table thead th, #element table tbody td {
    padding: 0;
    border: 1px solid black !important;
    padding-top: 1rem;
    padding-bottom: 1rem;


}


.option {
    width: 33%;
    /* display:flex;
    justify-content: center; */
    
}
</style>