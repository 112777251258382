<template >
    <prsview v-if="!showCreate" :prs="prs">

    </prsview>
    <prsCreate :edit="edit" :selected_prs_data="selected_prs" v-else>

    </prsCreate>
</template>
<script>
import prsview from './components/prs_view.vue'
import prsCreate from './components/prs_create.vue'
export default {
    components: {
        prsview,
        prsCreate
    },
    data(){
        return {
            showCreate : false,
            edit : false,
            prs : [
                // {
                //     prs_supplier : [
                //     {       
                //         SUPPLIER_NUMBER : 8, 	NAME : "Test",	CONTACT_PERSON : 'Test',	CONTACT_NUMBER : 'Test',	HEADER_REF_NUM : 21 , SUPPLIER : 1
                //     }
                // ],
                //     prs_header : {
                        
                //     PRS_NUMBER: 21, PRS_DATE: '03/14/2023  12:00:00 am', PRS_TYPE : 'Test 1',	REQUESTED_BY : 'SA', REMARKS :  'Test Remarks'
                //  } ,

                //     prs_detail : [
                //         {
                //             DETAIL_NUMBER : 2,	ON_HAND: 1,	REQUESTED : 1 ,	ITEM_CODE : 'qwe' ,	DESCRIPTION : 'qwe' ,	SUPPLIER_1: 1 , SUPPLIER_2 : 1,	SUPPLIER_3 	: 1 , TOTAL_COST : 123,	PRS_REF_NUMBER : 21
                //         },
                //         {
                //             DETAIL_NUMBER : 3,	ON_HAND:1,REQUESTED : 1,	ITEM_CODE :'123' ,	DESCRIPTION : '123' ,	SUPPLIER_1: 1 , SUPPLIER_2 : 1 ,	SUPPLIER_3 	: 1, TOTAL_COST : 123,	PRS_REF_NUMBER : 21
                //         },
                //         {
                //             DETAIL_NUMBER : 4,	ON_HAND: 1,	REQUESTED :  1,	ITEM_CODE : '1313q' ,	DESCRIPTION : 'qwe' ,	SUPPLIER_1: 1, SUPPLIER_2 :1 ,	SUPPLIER_3 	: 1, TOTAL_COST : 1 ,	PRS_REF_NUMBER : 21
                //         }
                //     ]
           

                // }
            ],
            selected_prs : [],
            Searcher : '',
        }
    },
     mounted(){
        this.retrievePRS()
    },
    provide(){
        return {
            "show" : this.show,
            "showEdit" : this.showEdit,
            "Search" : this.Search,
            "refresh" :this.refresh
        }
    },
    methods: {
        async refresh(){
            this.prs = []
            this.Searcher = ''
            await this.retrievePRS()
        },
        async Search(data){

            this.Searcher = data
            this.prs = []
            await this.retrievePRS()
        },
        show(){
            if(this.showCreate){
                this.edit = false
                this.selected_prs = []
            }
            this.showCreate = !this.showCreate
            this.prs = []
            this.retrievePRS()
           
        },
        showEdit(data){
  
            this.selected_prs = this.prs[data]
            this.edit = true
            this.show()
        },
      async  retrievePRS(){
         await   this.API.retrievePRS(this.Searcher)
            .then(res => {
                this.prs = res.data
     
            })
            .catch(err => {
                console.log(err)
            })
        }
    }
    
}
</script>
<style scoped>
    
</style>