<template>
    <prsContainer>
        <template #body>
            <div class="d-flex"> 
                <div class="d-flex" style="width: 50%;">  
                  
                    <div class="card mr-2" style="width: 100%;">
                        <div class="card-header d-flex">
                            PURCHASE REQUISITION SLIP
                            
                            <div class="d-flex ml-auto">
                                <span v-if="editing" class="mr-2">
                                    <button class="btn btn-info btn-sm" @click="isEditMode = true" v-if="!isEditMode">
                                        Edit
                                    </button>
                                    <span v-else >
                                        <button class="btn btn-success btn-sm" @click="updatePRS" :disabled="isHeaderFilled" v-if="!isCreateLoading">
                                            update
                                        </button>
                                        <button class="btn btn-success btn-sm mr-2" v-else>
                                            <div class="spinner-border spinner-border-sm" role="status">
                                            <!-- <span class="sr-only">Loading...</span> -->
                                            </div>
                                            updating...
                                        </button>
                                    </span>
                                
                                </span>
                                <span v-else >
                                    <button class="btn btn-success btn-sm mr-2" @click="createPRS" :disabled="isHeaderFilled" v-if="!isCreateLoading">
                                        Create
                                    </button>
                                    <button class="btn btn-success btn-sm mr-2" v-else>
                                        <div class="spinner-border spinner-border-sm" role="status">
                                        <!-- <span class="sr-only">Loading...</span> -->
                                        </div>
                                        Loading..
                                    </button>
                               
                                </span>
                         
                                <button class="btn btn-secondary ml-auto btn-sm" @click="show" v-if="!isEditMode" :disabled="isCreateLoading">
                                    Close
                                </button>
                                <button class="btn btn-secondary ml-auto btn-sm" @click="isEditMode = false" v-else :disabled="isCreateLoading">
                                    Cancel
                                </button>
                            </div>
                        </div>
              
                        <div class="card-body d-flex flex-column">
                            <div class="card">
                                <div class="card-header">
                                    Header
                                </div>
                                <div class="card-body d-flex flex-column">
                                    <div class="d-flex  justify-content-between">
                                        <div class=" sub-con">
                                            <label for="prs_no" class="form-label">
                                                PRS No.
                                            </label>
                                            <input type="text" class="form-control form-control-sm" id="prs_no" v-model="header.PRS_NUMBER" readonly>
                                        </div>
                                        <div class="mb sub-con">
                                            <label for="prs_date" class="form-label">
                                                PRS Date Needed
                                            </label>
                                            <input type="date" class="form-control form-control-sm" id="prs_date" v-model="header.PRS_DATE" :readonly="readOnly">
                                        </div>
                                  
                                    </div>
                                    <div class="d-flex flex-column justify-content-between mt-2">
                                        <h6>PRS TYPE</h6>
                                        <div class="d-flex justify-content-between pr-3">
                                            <input type="text" class="form-control form-control-sm mb-2" aria-label="Text input with radio button" placeholder="Other" v-model="header.PRS_TYPE" 
                                                :readonly="readOnly">
                                        </div>
                                        <div class="d-flex justify-content-between ">
                                        
                                            <div class="mb sub-con">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text ">
                                                            <input type="radio" aria-label="Radio button for following text input" v-model="header.PRS_TYPE" value="Imported Purchase"  :disabled="readOnly">
                                                        </div>
                                                    </div>
                                                    <input type="text" class="form-control form-control-sm" aria-label="Text input with radio button" readonly value="Imported Purchase">
                                                </div>
                                            </div>
                                            

                                            <div class="mb sub-con">
                                    
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text">
                                                            <input type="radio" aria-label="Radio button for following text input"  v-model="header.PRS_TYPE"  value="Local Purchase"  :disabled="readOnly">
                                                        </div>
                                                    </div>
                                                    <input type="text" class="form-control form-control-sm" aria-label="Text input with radio button" readonly value="Local Purchase">
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="d-flex justify-content-between mt-2">
                                            <div class="mb sub-con">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text">
                                                            <input type="radio" aria-label="Radio button for following text input"  v-model="header.PRS_TYPE"  value="Office Service"  :disabled="readOnly">
                                                        </div>
                                                    </div>
                                                    <input type="text" class="form-control form-control-sm" aria-label="Text input with radio button" readonly value="Office Service">
                                                </div>
                                            </div>

                                            <div class="mb sub-con">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text">
                                                            <input type="radio" aria-label="Radio button for following text input"  v-model="header.PRS_TYPE"  value="Warehouse service purchase"  :disabled="readOnly">
                                                        </div>
                                                    </div>
                                                    <input type="text" class="form-control form-control-sm" aria-label="Text input with radio button" readonly value="Warehouse service purchase">
                                                </div>
                                            </div>

                                        </div>

                                        <!-- <div class="d-flex justify-content-between mt-2">
                                            <div class="mb sub-con">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text">
                                                            <input type="radio" aria-label="Radio button for following text input"  v-model="header.prs_type"  value="5">
                                                        </div>
                                                    </div>
                                                    <input type="text" class="form-control form-control-sm" aria-label="Text input with radio button" placeholder="Other" v-model="header.prs_type" >
                                                </div>
                                            </div>
                                        </div>
                                  -->
                                       
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header d-flex">
                        
                                        Supplier
                           
                                    <div class="ml-auto">
                                        <button type="button" class="btn btn-primary btn-sm ml-auto mb-2" data-toggle="modal" data-target="#supplierModal" :disabled="suppliers.length == 3" @click="showAddsupplier" v-if="!editing || editing && isEditMode" >Add</button>
                                    </div>
                                </div>
                                <div class="card-body d-flex flex-column">
                                    <div class="card detail-card" v-for="(supplier,index) in suppliers" :key="index" @click="showEditSupplier(index)" data-toggle="modal" data-target="#supplierModal">
                               
                                        <div class="d-flex">
                                            <h6 class="ml-2 mt-2">Supplier {{ index + 1 }}</h6>
                                        <button type="button" class="btn btn-primary btn-sm ml-auto mb-2 mt-2 mr-2" data-toggle="modal" @click="removeSupplier(index)" v-if="!readOnly">remove</button>

                                        </div>
                                        <div class="card-body d-flex justify-content-between">
                                          
                                                <div class="sub-con d-flex flex-column">
                                                    <label>Supplier Name</label>
                                                    <input type="text" class="form-control form-control-sm" :value="supplier.NAME" readonly>
                                                </div>
                                                <div class="sub-con d-flex flex-column">
                                                    <label>Supplier Number</label>
                                                    <input type="text" class="form-control form-control-sm" :value="supplier.CONTACT_NUMBER" readonly>
                                                </div>
                                       
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="d-flex" style="width: 50%;">
                    <div class="card"  style="width: 100%;">
                        <div class="card-body  d-flex flex-column">

                            <div class="card d-flex flex-column">
                                <div class="card-header">
                                    Remarks
                                </div>
                                <div class="card-body">
                                    <textarea class="form-control" v-model="header.REMARKS" :readonly="readOnly">
                                    </textarea>
                                </div>
                      
                                <p style="font-size: 12px;" class="text-end mr-3"> {{  checklength }} / 100</p>
                         

                           </div>

                            <div class="card">
                                <div class="card-header d-flex">
                                    Details
                                    <button class="btn btn-primary btn-sm ml-auto mb-2 " @click="showAddDetail()"  data-toggle="modal" data-target="#detailModal" v-if="!editing || editing && isEditMode">Add</button>
                                </div>
                                <div class="card-body d-flex flex-column" >
            

                                    <div class="card detail-card" v-for="(detailed,index) in details" :key="index"  @click="showEditDetail(index)"  data-toggle="modal" data-target="#detailModal">

                                            <div class="d-flex">
                                    
                                            <button type="button" class="btn btn-primary btn-sm ml-auto mb-2 mt-2 mr-2" @click="showEditDetail(index)"  data-toggle="modal" data-target="#detailModal">Viemore</button>

                                            </div>
                                            <div class="card-body d-flex justify-content-between">
                                            
                                                    <div class="sub-con d-flex flex-column">
                                                        <label>Item Description</label>
                                                        <input type="text" class="form-control form-control-sm" :value="detailed.DESCRIPTION" readonly>
                                                    </div>
                                                    <div class="sub-con d-flex flex-column">
                                                        <label>Total Cost</label>
                                                        <input type="text" class="form-control form-control-sm" :value="detailed.TOTAL_COST" readonly>
                                                    </div>
                                        
                                            </div>

                                    </div>
                                </div>
                           </div>
                    
                        </div>
                    </div>
                </div>
            </div>
            <!-- <hr>
            <div class="d-flex mt-auto">
                <span v-if="editing">
                    <button class="btn btn-info" @click="isEditMode = true" v-if="!isEditMode">
                        Edit
                    </button>
                    <button class="btn btn-success" @click="update" v-else>
                        update
                    </button>
                </span>
                <button class="btn btn-success" @click="createPRS" v-else >
                    Create
                </button>
                <button class="btn btn-secondary ml-auto" @click="show">
                    Close
                </button>
            </div> -->
        </template>

        <template #footer>

          
        </template>
    </prsContainer>


    <!-- Supplier modal -->
    <div class="modal fade" id="supplierModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Add Supplier</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="mb-2" >
                        <label for="name" class="form-label">
                        Supplier Name
                        </label>
                        <input type="text" class="form-control form-control-sm" id="name" v-model="supplier.NAME" :readonly="readOnly">
                    </div>
                    <div class="mb-2" >
                        <label for="contact_person" class="form-label">
                            Contact Person
                        </label>
                        <input type="text" class="form-control form-control-sm" id="contact_person" v-model="supplier.CONTACT_PERSON" :readonly="readOnly">
                    </div>
                    <div class="mb-2" >
                        <label for="contact_number" class="form-label">
                            Contact Number
                        </label>
                        <input type="text" class="form-control form-control-sm" id="contact_number" v-model="supplier.CONTACT_NUMBER" :readonly="readOnly"> 
            </div>
            </div>
            <div class="modal-footer d-flex">
                <span v-if="!isEdit">  
                        <button class="btn btn-sm btn-success" @click="addSupplier(supplier)"  :disabled="isSupplierFilled">
                            Add
                        </button>
                 </span>
                    <span v-if="isEdit && isEditMode || isEdit && !editing">  
                        <button class="btn btn-sm btn-success"  @click="updateSupplier(supplier)" data-dismiss="modal" :disabled="isSupplierFilled">
                            update
                        </button>
                        <button class="btn btn-sm btn-danger ml-3"  @click="removeSupplier(index)" data-dismiss="modal" >
                            remove
                        </button>
                </span>
                <button type="button" class="btn btn-secondary btn-sm ml-auto" data-dismiss="modal" id="supplierClose">Close</button>

            </div>
            </div>
        </div>
    </div>

    <!-- Detail Modal    -->
    <div class="modal fade" id="detailModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Add Detail</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex flex-column">
                <div class="d-flex flex-column mb-3">
                <h4>Quantity</h4>
                <div>
                    <label for="on_hand" class="form-label">
                        On Hand
                    </label>
                    <input type="number" class="form-control form-control-sm" id="on_hand" v-model="detail.ON_HAND" :readonly="readOnly" @keyup="computes">
                </div>
                <div>
                    <label for="requested" class="form-label">
                        Requested
                    </label>
                    <input type="number" class="form-control form-control-sm" id="requested" v-model="detail.REQUESTED" :readonly="readOnly" @keyup="computes">
                </div>
            </div>
            <div class="d-flex flex-column mb-3">
                <h4>Details</h4>
                <div>
                    <label for="item_code" class="form-label">
                        Item Code
                    </label>
                    <input type="text" class="form-control form-control-sm" id="item_code" v-model="detail.ITEM_CODE" :readonly="readOnly">
                </div>
                <div>
                    <label for="description" class="form-label">
                        Description
                    </label>
                    <input type="text" class="form-control form-control-sm" id="description" v-model="detail.DESCRIPTION" :readonly="readOnly">
                </div>
            </div>
            <!-- <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <input type="radio" aria-label="Radio button for following text input"  v-model="header.PRS_TYPE"  value="Local Purchase"  :disabled="readOnly">
                    </div>
                </div>
                <input type="text" class="form-control form-control-sm" aria-label="Text input with radio button" readonly value="Local Purchase">
             </div> -->
             <div class="d-flex flex-column  mb-3">
                <h4>CURRENCY</h4>
                <div class="d-flex justify-content-between">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="input-group-text">
                                <input type="radio" aria-label="Radio button for following text input"  v-model="detail.CURRENCY"  value="US$"  :disabled="readOnly">
                            </div>
                        </div>
                        <input type="text" class="form-control form-control-sm" aria-label="Text input with radio button" readonly value="USD">
                    </div>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="input-group-text">
                                <input type="radio" aria-label="Radio button for following text input"  v-model="detail.CURRENCY"  value="PHP"  :disabled="readOnly">
                            </div>
                        </div>
                        <input type="text" class="form-control form-control-sm" aria-label="Text input with radio button" readonly value="PHP">
                    </div>
                </div>
            </div>

            <div class="d-flex flex-column">
                <h4>Unit Cost</h4>
                <div class="d-flex justify-content-between">
                    <div class="d-flex flex-column" style="width: 48%;">
                        <div>
                            <label for="sup1" class="form-label">
                                Supplier 1
                            </label>
                            <!-- <input type="number" class="form-control form-control-sm" id="sup1" v-model="detail.SUPPLIER_1"  :readonly="!isEditMode && editing || suppliers.length == 0" @keyup="computes"> -->
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <input type="radio"  v-model="detail.FLAG"  value="1"  :disabled="!isEditMode && editing || suppliers.length == 0"  @change="computes">
                                    </div>
                                </div>
                                <!-- <input type="text" class="form-control form-control-sm" aria-label="Text input with radio button" readonly value="Local Purchase"> -->
                               <input type="number" class="form-control form-control-sm" id="sup1" v-model="detail.SUPPLIER_1"  :readonly="!isEditMode && editing || suppliers.length == 0" @keyup="computes">

                            </div>
                       
                        </div>
                        <div>
                            <label for="sup2" class="form-label">
                                Supplier 2
                            </label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <input type="radio"  v-model="detail.FLAG"  value="2"  :disabled="!isEditMode && editing || suppliers.length < 2"  @change="computes" >
                                    </div>
                                </div>
                            <input type="number" class="form-control form-control-sm" id="sup2" v-model="detail.SUPPLIER_2"  :readonly="!isEditMode && editing || suppliers.length < 2 " @keyup="computes">
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column" style="width: 48%;">
                        <div>
                            <label for="sup3" class="form-label">
                                Supplier 3
                            </label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <input type="radio"  v-model="detail.FLAG"  value="3"  :disabled="!isEditMode && editing || suppliers.length < 3" @change="computes">
                                    </div>
                                </div>
                            <input type="number" class="form-control form-control-sm" id="sup3" v-model="detail.SUPPLIER_3"  :readonly="!isEditMode && editing || suppliers.length  < 3 " @keyup="computes">
                            </div>
                        </div>
                        <div>
                            <label for="total" class="form-label">
                                Total
                            </label>
                            <input type="number" class="form-control form-control-sm" id="total" v-model="detail.TOTAL_COST"  readonly>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="modal-footer d-flex">
                <span v-if="!isEdit">  
                <button class="btn btn-sm btn-success" data-dismiss="modal" @click="addDetail(detail)" :disabled="isFulfilledDetail">
                    Add
                </button>
                </span>
                <span v-if="isEdit && isEditMode || isEdit && !editing">  
                    <button class="btn btn-sm btn-success " data-dismiss="modal" @click="updateDetail(detail)" :disabled="isFulfilledDetail" >
                        update
                    </button>
                    <button class="btn btn-sm btn-danger ml-3"  data-dismiss="modal" @click="removeDetail(index)">
                        remove
                    </button>
                </span>

         
                <button type="button" class="btn btn-secondary btn-sm ml-auto " data-dismiss="modal" id="detailClose">Close</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
            </div>
        </div>
    </div>

</template>
<script>

import prsContainer  from './prs_container.vue'

export default {
    name:"PRSCreate",
    props: ['edit','selected_prs_data'],
    inject:['show'],
    components :{ 
        prsContainer,

    },
    data(){
        return {
            isCreateLoading: false,
            editing : false,
            prs_type : '',
            index : null,
            isEdit: false,
            isShow : false,
            isEditMode : false,
            selected_data : [],
            details : [],
            suppliers : [

            ],
            header : {
                PRS_NUMBER : '',
                PRS_DATE: '',
                PRS_TYPE : 'Local Purchase',
                REMARKS : ''
            },

            supplier : [],
            detail : {
                SUPPLIER_1 : 0,
                SUPPLIER_2 : 0,
                SUPPLIER_3 : 0,
                ON_HAND : 0,
                REQUESTED : 0,

            },

        }
    },
    computed : {
        isHas(){ 
                // const body = document.getElementsByTagName('body')
                return document.body.classList.contains('ks-right-bar-open')
            },
        totalCost(){

                // let ON_HAND = this.detail.ON_HAND ? this.detail.ON_HAND * 1 : 0
                let REQUESTED = this.detail.REQUESTED  ? this.detail.REQUESTED * 1 : 0
                let sup1 = this.detail.SUPPLIER_1 ?  this.detail.SUPPLIER_1 * 1 : 0
                let sup2 = this.detail.SUPPLIER_2 ? this.detail.SUPPLIER_2 * 1 : 0
                let sup3 = this.detail.SUPPLIER_3 ? this.detail.SUPPLIER_3 * 1 : 0
                let flag = this.detail.FLAG ? this.detail.FLAG * 1  : 0;
                let total = 0 
                // let total_quantity = ON_HAND + REQUESTED
                // // let sub_total = sup1 + sup2 + sup3
                // let sub_total = (sup1 * flag) + (sup2 * flag ) + sup3

                let sub_total = 0;

                if(flag == 1){
                    sub_total = (sup1 * 1) + (sup2 * 0) + (sup3 * 0);
                }
                if(flag == 2){
                    sub_total = (sup1 * 0) + (sup2 * 1) + (sup3 * 0);
                }
                if(flag == 3){
                    sub_total = (sup1 * 0) + (sup2 * 0) + (sup3 * 1);
                }

       
                total = REQUESTED * sub_total

                return total

        },
        HasValue(){
                return this.suppliers.length > 0 && this.details.length > 0 
        },
        readOnly() {
                return !this.isEditMode && this.editing
        },
        isFulfilledDetail(){
                // let sup =
                let filled = this.detail.CURRENCY === undefined ||  this.suppliers.length == 0 || this.detail.DESCRIPTION == ''  || this.detail.DESCRIPTION === undefined  || this.detail.TOTAL_COST === undefined || this.detail.TOTAL_COST == 0 || this.detail.FLAG === undefined

                return  filled
        },
        isHeaderFilled(){
            let filled = this.header.PRS_DATE === undefined ||  this.suppliers.length == 0 ||   this.details.length == 0 || this.header.REMARKS === undefined  || this.header.PRS_TYPE === undefined ||  this.header.REMARKS == '' 
            return  filled
        },
        isSupplierFilled(){
            let filled = this.supplier.NAME === undefined || this.supplier.CONTACT_NUMBER === undefined
            return  filled
        },
        checklength(){
            return this.header.REMARKS.length
        }
    },
    provide() {
        return{
            'addSupplier' : this.addSupplier,
            'updateSupplier' : this.updateSupplier,
            'removeSupplier' : this.removeSupplier,

            'addDetail' : this.addDetail,
            'updateDetail': this.updateDetail,
            'removeDetail' : this.removeDetail,

            'close' : this.showSidebar
        }
    },
    methods : {
        computes(){
            if(!this.detail.FLAG){
                this.detail.FLAG = 1;
            }
           this.detail.TOTAL_COST = this.totalCost
        },
        closeModal(id){
            let element = document.getElementById(id)
            element.click()
        },
        showAddsupplier(){
            this.supplier = []
            this.isEdit = false
        },
        showAddDetail(){
            this.detail = []
            this.isEdit = false
        },
        showEditSupplier(index){
       
            this.index = index
            this.supplier = this.suppliers[index]
            this.isEdit = true
        },
    
        showEditDetail(index){
            this.index = index
            this.detail =  this.details[index]
            this.isEdit = true
        },
        convertNaNtoNumber(index){
            this.details[index].ON_HAND = this.details[index].ON_HAND * 1
            this.details[index].SUPPLIER_1 = this.details[index].SUPPLIER_1* 1
            this.details[index].SUPPLIER_2 = this.details[index].SUPPLIER_1* 2
            this.details[index].SUPPLIER_3 = this.details[index].SUPPLIER_1* 3
            this.details[index].REQUESTED = this.details[index].REQUESTED * 1
            this.details[index].TOTAL_COST = this.details[index].TOTAL_COST * 1


        },
        addSupplier(supplier){

            if(Object.keys(supplier).length > 0){
                this.suppliers.push(supplier)
                this.isEdit = true
                this.closeModal('supplierClose')

            }
            else return
   

        },
        updateSupplier(supplier){
            if(supplier){
                this.suppliers[this.index] = supplier
            }
            else return
          
        },
        removeSupplier(index){
            this.suppliers.splice(index,1)

        },
        //////
        addDetail(detail){
            if(Object.keys(detail).length > 0){
                this.details.push(detail)
                this.isEdit = true
                this.closeModal('detailClose')
            }
            else return

        },
        updateDetail(detail){
            if(detail){
                this.details[this.index] = detail
            }
            else return
        },
        removeDetail(index){
            this.details.splice(index,1)
        
        },
        async createPRS(){
            this.isCreateLoading = true

            await this.API.prsCreate(this.header,this.details,this.suppliers)
            .then( res => {
                this.editing = true
                let data = res.data[0].message[0]
                this.header = data.prs_header
                this.details = data.prs_detail
                this.supplier = data.prs_supplier
                // this.header.PRS_DATE = this.API.convertDate(this.header.PRS_DATE)
                this.header.PRS_DATE = this.$moment(this.header.PRS_DATE).format('YYYY-MM-DD')


                this.$jlalert.show("Create Success", res.status != 200);
             
            })
            .catch( err => {
                console.log(err)
            })
            .finally( this.isCreateLoading = false)

        },
        async updatePRS(){
            this.isCreateLoading = true
            await this.API.prsUpdate(this.header,this.details,this.suppliers)
            .then( res => {
           
                let data = res.data.message[0];

                this.header = data.prs_header
                this.details = data.prs_detail
                this.supplier = data.prs_supplier
                // this.header.PRS_DATE = this.API.convertDate(this.header.PRS_DATE)
                this.header.PRS_DATE = this.$moment(this.header.PRS_DATE).format('YYYY-MM-DD')

                
                this.$jlalert.show("update Success", res.status != 200);
                this.isEditMode = false

            })
            .catch(err => {
                console.log(err)
            })
            .finally( this.isCreateLoading = false )
        }
        
    },
    mounted() {

        this.editing = this.edit

        if('prs_header' in this.selected_prs_data) {
                this.header = this.selected_prs_data.prs_header
                this.details = this.selected_prs_data.prs_detail
                this.suppliers = this.selected_prs_data.prs_supplier

                this.header.PRS_DATE = this.$moment(this.header.PRS_DATE).format('YYYY-MM-DD')

            }
        else {
            let date = new Date()
            // this.header.PRS_DATE = this.API.convertDate(date)
            this.header.PRS_DATE = this.$moment(date).format('YYYY-MM-DD')
        }
     
        
    }

}

</script>
<style scoped>
label{
    font-size: 10px;
}

hr{
    margin : 0;
}
/* input {
    height: 10px;
} */

select {
    /* height: px; */
}
.sub-con {
    min-width: 40%;
    /* max-width: 30%; */
    margin-right: 1rem;


}

.detail-card{
    cursor: pointer;
}
detail-card:hover{
    background-color: grey;
}

td,th{
    text-align: center;
    border-right: 1px solid gray;
    border-left: 1px solid gray;

}
tr {
    cursor: pointer;  
}
</style>